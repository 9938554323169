import React, { useGlobal } from 'reactn';
import { useState } from 'react';
import { makeStyles, AppBar, Typography, IconButton } from '@material-ui/core';

import ProfileIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import logo from '../../assets/images/logo.png';
import authService from '../../services/auth-service';
import MobileNavbar from './MobileNavbar';
import NavbarMenu from './Navbar-Menu/NavbarMenu';
import NavbarSelect from './Navbar-Select/NavbarSelect';

const useStyles = makeStyles((theme) => ({
  navbar: {
    height: 87,
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
  },
  navbarContent: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logoArea: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userArea: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userText: {
    flex: 0.2,
    marginLeft: theme.spacing(1),
    fontFamily: 'Montserrat',
    color: '#7882A2',
  },
  expandUserMenuButton: {
    color: '#FFF',
  },
  selectMenuItemText: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
}));

const Navbar = ({ history }) => {
  const classes = useStyles();
  const [user, setUser] = useGlobal('user');

  const [anchorEl, setAnchorEl] = useState(null);

  const logout = () => {
    setUser({ name: '', type: 'INVESTOR' });
    authService.unauthenticated();
    history.push('/');
  };

  return !isMobile ? (
    <AppBar position='static' className={classes.navbar}>
      <div className={classes.navbarContent}>
        <div className={classes.logoArea}>
          <a href='http://www.ameaconstrutora.com.br'>
            <img src={logo} alt='Logo Amea' width={150} className={classes.logo} />
          </a>
        </div>
        <div className={classes.userTypeSelectionArea}>
          <NavbarSelect user={user} history={history} />
        </div>
        <div className={classes.userArea}>
          <ProfileIcon fontSize='large' />
          <div className={classes.userText}>
            <Typography variant='body2'>Olá,</Typography>
            <Typography variant='body2'>{user?.name?.split(' ')[0]}</Typography>
          </div>
          <IconButton className={classes.expandUserMenuButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
            <ExpandMoreIcon />
          </IconButton>
          <NavbarMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} onLogout={logout} />
        </div>
      </div>
    </AppBar>
  ) : (
    <MobileNavbar />
  );
};

export default withRouter(Navbar);
