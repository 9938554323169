import React, { useEffect, useState } from 'react';
import { useGlobal } from 'reactn';
import { CircularProgress, makeStyles, Paper, Typography } from '@material-ui/core';

import CountUp from 'react-countup';

import clone from '../../lib/clone';

import Chart from '../Charts';
import InvestmentSimulator from '../Investment-simulator/InvestmentSimulator';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import MobileReferrer from './MobileReferrer';
import ReferrerController from '../../controllers/ReferrerController';
import UserViewController from '../../controllers/UserViewController';
import UserViews from '../../constants/user-views';
import ProgressLevelIndicators from '../Construction-progress/ProgressLevelIndicators';

const useStyles = makeStyles((theme) => ({
  main: {
    flex: 1,
    minHeight: '80vh',
    display: 'flex',
    marginTop: window.screen.width <= 1280 ? theme.spacing(2) : theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      alignItems: 'center',
      marginTop: 0,
    },
  },
  loading: {
    flex: 1,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  leftArea: {
    flex: 0.14,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  rightArea: {
    flex: 0.14,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  centerArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'hidden',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  indicationsArea: {
    flex: 0.4,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: theme.spacing(1),
    },
  },
  indicationsCenterArea: {
    flex: 0.1,
  },
  indicationSimulatorArea: {
    flex: 1,
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  indicationsValuesAndLevels: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  indicationsValuesArea: {
    flex: 1,
    display: 'flex',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      alignItems: 'center',
      marginTop: theme.spacing(3),
    },
  },
  indicationsValuesCenterArea: {
    flex: 0.1,
  },
  indicationsInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      alignItems: 'center',
    },
  },
  indicationInfoTitleArea: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  indicationInfoTitle: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    color: '#333333',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  indicationInfoLine: {
    flex: 0.05,
    backgroundColor: '#EFEFEF',
  },
  indicationInfoValueArea: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  IndicationInfoValue: {
    fontFamily: 'Montserrat',
    color: 'red',
  },
  indicationLevelsArea: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      flex: 1,
    },
  },
  progressIndicator: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  indicationCenterAreaCenterSpace: {
    flex: 0.1,
  },
  indicationChartArea: {
    flex: 0.8,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  indicationChart: {
    flex: 1,
    display: 'flex',
  },
  indicationChartPaper: {
    flex: 1,
    padding: theme.spacing(2),
  },
  centerBottomArea: {
    flex: 0.2,
  },
}));

const Referrer = ({ history }) => {
  const classes = useStyles();

  const [user] = useGlobal('user');
  const [indications, setIndications] = useGlobal('indications');
  const [totalIndicationsChart, setTotalIndicationsChart] = useState({
    indications: [],
    accumulated: [],
    sales: [],
  });
  const [levels, setLevels] = useGlobal('levels');
  const [loading, setLoading] = useState(true);
  const [currentLevel, setCurrentLevel] = useState(null);
  const [totalIndications, setTotalIndications] = useState(0);
  const [totalCommissions, setTotalCommissions] = useState(0);

  useEffect(() => {
    // if (history.action === 'POP') {
    //   history.push('/');
    // }
    if (user.type !== 'REFERRER' && user.type !== 'INVESTOR/REFERRER') {
      history.push('/');
    }
    if (!user.id) {
      history.push('/');
    }

    moment.locale('pt-br');
  }, []); //eslint-disable-line

  useEffect(() => {
    UserViewController.loadView(UserViews.REFERRER);
    (async () => {
      const indications = await ReferrerController.fetchIndications({ userId: user.id });
      setIndications(indications);
      const levels = await ReferrerController.fetchLevels();
      setLevels(levels);
      setLoading(false);
    })();
  }, []); //eslint-disable-line

  useEffect(() => {
    let totalIndications = 0;

    if (indications?.totalIndications) {
      setTotalIndicationsChart(clone(indications.totalIndications));
    }
    if (indications?.totalIndications && indications?.totalIndications.indications.length) {
      totalIndications = indications?.totalIndications.indications.reduce((acc, cur) => acc + cur.y, 0);
      setTotalIndications(totalIndications);
    }
  }, [indications]); //eslint-disable-line

  useEffect(() => {
    const newLevel = ReferrerController.onTotalIndicationsChangeHandler({ levels, totalIndications });
    setCurrentLevel(newLevel);
  }, [levels, totalIndications]);

  useEffect(() => {
    if (currentLevel?.level) setTotalCommissions((totalIndications * currentLevel.level) / 100);
  }, [currentLevel, totalIndications]);

  const onSimulate = async (value) => {
    if (indications) {
      const currentChart = clone(indications.totalIndications);
      const currentMonth = `${moment().format('MM')}/${moment().format('YY')}`;
      const currentMonthIndication = currentChart['indications'].find((indication) => indication.x === currentMonth);
      currentChart['Indicações (SIMULAÇÃO)'] = clone(currentChart['indications']);
      currentChart['Acumulado (SIMULAÇÃO)'] = clone(currentChart['accumulated']);

      const totalIndications = clone(indications.totalIndications.indications).reduce((acc, cur) => acc + cur.y, 0);

      if (currentMonthIndication) {
        for (let i = 0; i < currentChart['indications'].length; i++) {
          if (currentChart['indications'][i].x === currentMonth) {
            currentChart['Indicações (SIMULAÇÃO)'][i].y = currentMonthIndication.y + value;
            currentChart['Acumulado (SIMULAÇÃO)'][i].y = totalIndications + value;
          } else {
            currentChart['Indicações (SIMULAÇÃO)'][i].y = 0;
            currentChart['Acumulado (SIMULAÇÃO)'][i].y = 0;
          }
        }
      }

      setTotalIndicationsChart(currentChart);
      const newTotalIndications = totalIndications + value;
      setTotalIndications(newTotalIndications);
      // const newCurrentLevel = ReferrerModel.getCurrentLevel(levels, newTotalIndications);
      // setTotalCommissions((newTotalIndications * newCurrentLevel) / 100);
      // const simulation = await dataService.fetchSimulation(value);
      // setTotalCommissions(simulation?.commission);
    }
  };

  const onCloseInvestmentSimulator = () => {
    setTotalIndicationsChart(indications?.totalIndications);

    const totalIndications = indications?.totalIndications.indications.reduce((acc, cur) => acc + cur.y, 0);
    setTotalIndications(totalIndications);
  };

  const IndicationInfo = ({ title, value }) => {
    return (
      <div className={classes.indicationsInfo}>
        <div className={classes.indicationInfoTitleArea}>
          <Typography className={classes.indicationInfoTitle} variant='body2'>
            {title}
          </Typography>
        </div>
        <div className={classes.indicationInfoLine}>{/* <hr></hr> */}</div>
        <div className={classes.indicationInfoValueArea}>
          <CountUp
            start={0}
            end={value}
            duration={3}
            separator={'.'}
            prefix={'R$'}
            style={{
              fontFamily: 'Montserrat',
              fontWeight: 'bold',
              color: '#52575D',
              fontSize: isMobile ? 20 : 28,
            }}
          ></CountUp>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return !isMobile ? (
    <div className={classes.main}>
      <div className={classes.leftArea}></div>
      <div className={classes.centerArea}>
        <div className={classes.indicationsArea}>
          <div className={classes.indicationSimulatorArea}>
            <InvestmentSimulator onSimulate={onSimulate} onCancel={onCloseInvestmentSimulator} />
          </div>
          <div className={classes.indicationsCenterArea}></div>
          <div className={classes.indicationsValuesAndLevels}>
            <div className={classes.indicationsValuesArea}>
              <IndicationInfo title='Total investido através de suas indicações' value={totalIndications} />
              <div className={classes.indicationsValuesCenterArea}></div>
              <IndicationInfo title='Comissão por indicação' value={totalCommissions} />
            </div>
            <div className={classes.indicationLevelsArea}>
              {!loading && levels?.length ? (
                <ProgressLevelIndicators
                  currentLevel={currentLevel}
                  levels={levels}
                  totalIndications={totalIndications}
                  classes={classes}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className={classes.indicationCenterAreaCenterSpace}></div>

        <div className={classes.indicationChart}>
          <Paper className={classes.indicationChartPaper} elevation={5}>
            <div className={classes.indicationChartArea}>
              <Chart
                chartTitle={'Indicações e Acumulado'}
                data={totalIndicationsChart}
                chartType='bar'
                yMoney
                chartColors={['#5693F4', '#FFB300', '#0FD901', '#ff0000', '#c3cde4']}
                yMin={0}
                width={(70 * window.screen.width) / 100}
                height={(30 * window.screen.height) / 100}
                /* width={400}
                height={window.screen.width <= 1400 ? 250 : 400} */
                yMax={totalIndications + totalIndications / 3}
              />
            </div>
          </Paper>
        </div>
        <div className={classes.centerBottomArea}></div>
      </div>
      <div className={classes.rightArea}></div>
    </div>
  ) : (
    <MobileReferrer
      totalIndications={totalIndications}
      totalCommissions={totalCommissions}
      onSimulate={onSimulate}
      onCloseInvestmentSimulator={onCloseInvestmentSimulator}
      currentLevel={currentLevel}
      levels={levels}
      totalIndicationsChart={totalIndicationsChart}
    />
  );
};

export default withRouter(Referrer);
