import Amplify from 'aws-amplify';
import app from '../config/app';
import awsconfig from '../aws-exports';

export default function loadAmplifyConfig() {
  if (app.apiURL) {
    awsconfig.aws_cloud_logic_custom[0].endpoint = app.apiURL;
  }
  Amplify.configure(awsconfig);
}
