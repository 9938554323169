import React, { useState } from 'react';
import { Progress } from 'react-sweet-progress';
import { useEffect } from 'react';
import { makeStyles, Typography, Tooltip, Paper } from '@material-ui/core';

import 'react-sweet-progress/lib/style.css';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  progressIndicator: {
    flex: 1,
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      minHeight: '7em',
    },
  },
  progressIndicatorPaper: {
    padding: theme.spacing(1),
    flex: 1,
    display: 'flex',
  },
  progressIndicatorPaperContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '0.6em',
  },
  progressIndicatorCircle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressIndicatorTextArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'right',
  },
  progressIndicatorPercentText: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: '0.8em',
    marginLeft: theme.spacing(1),
  },
  progressIndicatorTitleText: {
    color: '#52575D',
    fontFamily: 'Montserrat',
  },
  progressIndicatorRightArea: {
    flex: 0.1,
  },
}));

const ProgressIndicator = ({ title, percentage, description = null, indication = false }) => {
  const [progress, setProgress] = useState(0.0);

  const classes = useStyles();

  useEffect(() => {
    setProgress(percentage);
  }, [percentage]);

  const Indicator = () => (
    <div className={classes.progressIndicatorPaperContent}>
      <div className={classes.progressIndicatorCircle} style={{ flex: indication ? 1 : 0.5 }}>
        <Progress
          type='circle'
          percent={progress}
          width={isMobile ? 30 : 50}
          style={
            {
              // fontSize: isMobile ? 9 : 13,
            }
          }
          theme={{
            default: { trailColor: 'lightblue' },
            /* success: {
                    trailColor: "lime",
                    symbol: 100 + "%",
                    color: "green"
                  } */
          }}
        />
      </div>
      <div className={classes.progressIndicatorTextArea} style={{ flex: indication ? 0.5 : 1 }}>
        <Typography
          className={classes.progressIndicatorPercentText}
          variant='h6'
          style={{
            color: progress >= 100 ? '#02D534' : '#0b0c32',
            // fontSize: isMobile ? 12 : '',
          }}
        >
          {title}
        </Typography>
        {!indication ? (
          <Typography
            className={classes.progressIndicatorTitleText}
            variant={isMobile ? 'body2' : 'body1'}
            style={{ fontSize: isMobile ? 10 : '' }}
          >
            {title}
          </Typography>
        ) : null}
      </div>
      <div className={classes.progressIndicatorRightArea} style={{ flex: indication ? 0.2 : 0.1 }}></div>
    </div>
  );

  return !indication ? (
    <div className={classes.progressIndicator}>
      <Tooltip title={description}>
        <Paper className={classes.progressIndicatorPaper} elevation={3} style={{ width: isMobile ? 110 : 200 }}>
          <Indicator />
        </Paper>
      </Tooltip>
    </div>
  ) : (
    <div
      className={classes.progressIndicator}
      style={{
        marginRight: '1em',
        minHeight: '3em',
      }}
    >
      <Paper className={classes.progressIndicatorPaper} elevation={3}>
        <Indicator />
      </Paper>
    </div>
  );
};

export default ProgressIndicator;
