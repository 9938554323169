import { API } from 'aws-amplify';
import app from '../../config/app';
import loadAmplifyConfig from '../load-amplify-config';

loadAmplifyConfig();

async function post(url, init = {}) {
  let fullURL = `/api/auth/${url}`;
  if (app.apiURL) {
    if (app.unitTest) fullURL = app.apiURL + fullURL;
    init.method = 'POST';
    init.body = JSON.stringify(init.body);
    init.headers = { ['Content-type']: 'application/json' };
    const response = await fetch(`${fullURL}`, init);
    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      throw new Error(`GET ${fullURL} ${response.status}: ${response.statusText}`);
    }
  }
  return API.post(app.amplifyApiName, fullURL, init);
}

class RemoteAuthProvider {
  lastAccessToken = null;

  setLastAccessToken(newAccessToken) {
    this.lastAccessToken = newAccessToken;
  }

  authenticate = async (login, password) => {
    const response = await post('login', {
      body: {
        login: login,
        password: password,
      },
    });
    return response;
  };
}

export default new RemoteAuthProvider();
