import Singleton from "../../lib/singleton";
import EventEmitter from "wolfy87-eventemitter";

class EventService extends Singleton {
  constructor() {
    super();

    this._eventEmmiter = new EventEmitter();
  }

  initialize() {}

  addListener(...props) {
    this._eventEmmiter.addListener(...props);
  }

  removeListener(...props) {
    this._eventEmmiter.removeListener(...props);
  }

  emitEvent(...props) {
    this._eventEmmiter.emitEvent(...props);
  }
}

export default new EventService();
