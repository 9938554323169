import React from 'react';
import Referrer from '../../models/Referrer';
import ProgressIndicator from './ProgressIndicator';

const ProgressLevelIndicators = ({ currentLevel, levels, totalIndications, classes }) => {
  return levels.map((level, index) => (
    <div className={classes.progressIndicator} key={index}>
      <ProgressIndicator
        indication
        key={level?.name}
        percentage={Referrer.getPercentage({ index, currentLevel, level, totalIndications })}
        title={Referrer.getLevelTitle(level)}
      />
    </div>
  ));
};

export default ProgressLevelIndicators;
