if (!process.env.REGION) {
  const dotenv = require('dotenv'); // eslint-disable-line
  const nodeFetch = require('node-fetch'); // eslint-disable-line
  dotenv.config();
  window.fetch = nodeFetch;
}

export default {
  apiURL: process.env.REACT_APP_API_URL,
  useLocalDataProvider: process.env.REACT_APP_LOCAL_DATA_PROVIDER === 'true' ?? false,
  amplifyApiName: process.env.REACT_APP_AMPLIFY_API_NAME,
  documentHashURL: process.env.REACT_APP_IZZYERP_DOCUMENT,
};
