import users from '../../data/users.json';
const localAuthProvider = {};

localAuthProvider.authenticate = async (username, password) => {
  const user = users.find((user) => user.document === username && user.password === password);
  if (user) {
    return {
      authenticated: true,
      accessToken: 'local',
      user,
    };
  } else {
    return {
      authenticated: false,
    };
  }
};

localAuthProvider.fetchUser = (type, params) => {
  const password = params.password ? params.password : '';
  const userData = { email: 'user', password };
  if (type === 'CREATE') {
    return Promise.resolve(userData);
  } else if (type === 'UPDATE') {
    return Promise.resolve(userData);
  } else if (type === 'GET_ONE') {
    return Promise.resolve({ data: userData });
  } else {
    return Promise.reject(new Error(`Type ${type} not supported`));
  }
};

export default localAuthProvider;
