import React from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';

import { makeStyles, ThemeProvider, createMuiTheme } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';

import authService from '../../services/auth-service';
import Main from '../Main/Main';
import Navbar from '../Navbar/Navbar';
import Login from '../Login/Login';
import Footer from '../Footer/Footer';
import Investor from '../Investor';
import Referrer from '../Referrer';
import config from '../../config/version';
const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#0b0c32',
        light: '#000A61',
      },
      secondary: {
        main: '#EFA900',
        dark: '#D59700',
        light: '#ffd63c',
      },
    },
    spacing: 10,
  },
  ptBR
);

const useStyles = makeStyles(() => ({
  main: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  nav: {},
  content: {
    flex: 1,
  },
  footer: {
    flexShrink: 0,
  },
}));

const ProtectedRoute = ({ component: Component, exact = false, path }) => {
  return (
    <Route
      exact={exact}
      render={() =>
        authService.isAuthenticated() ? <Component /> : <Redirect to={{ pathname: '/login', state: { from: path } }} />
      }
    />
  );
};

const DefaultLayout = ({ classes, component: Component, exact, path }) => {
  return (
    <div className={classes.main}>
      <div className={classes.nav}>
        <Navbar />
      </div>
      <div className={classes.content}>
        <ProtectedRoute component={Component} exact={exact} path={path} />
      </div>
      <footer className={classes.footer}>
        <Footer />
      </footer>
    </div>
  );
};

const App = () => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path='/login'>
            <Login />
          </Route>
          <DefaultLayout classes={classes} exact={true} path='/investidor' component={Investor} />
          <DefaultLayout classes={classes} exact={true} path='/indicador' component={Referrer} />
          <DefaultLayout classes={classes} exact={true} path='/' component={Main}></DefaultLayout>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

console.log(config.version);
export default App;
