import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { makeStyles, Dialog, DialogContent, CircularProgress } from '@material-ui/core';
import { useEffect } from 'react';
import eventService from '../../services/event-service';
import EventTypes from '../../constants/event-types';
import dataService from '../../services/data-service';
import noImage from '../../assets/not-image-file.png';
import config from '../../config/app';

const useStyles = makeStyles(() => ({
  image: {
    backgroundColor: '#fff !important',
    height: '100%',
    width: '100%',
  },

  img: {
    maxHeight: '65vh',
    objectFit: 'fill',
  },

  imagesCarousel: {
    flex: 1,
  },
  dialog: {
    overflowY: 'hidden',
  },
  imgName: {
    top: 0,
    position: 'absolute',
    fontWeight: 'bolder',
    fontSize: 12,
  },
}));

const ImagesCarousel = ({ images = [] }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [base64Images, setBase64Images] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    eventService.addListener(EventTypes.OPEN_IMAGES_DIALOG, () => {
      setOpen(true);
    });
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      let arrayImages = [];
      setLoading(true);
      // console.log(images);
      await Promise.all(
        images?.map(async (image) => {
          if (image.hash) {
            arrayImages.push({ ...image, src: `${config.documentHashURL}${image.hash}` });
          } else {
            const imageToDownload = await dataService.fetchDocument(image.path);
            arrayImages.push({ ...image, src: imageToDownload.content });
          }
        })
      );

      const sortedImages = arrayImages.sort((a, b) => {
        return a.name.localeCompare(b.name); //a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });

      setBase64Images(sortedImages);
      setLoading(false);
    };
    fetchImages();
  }, [images]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent className={classes.dialog}>
        <div className={classes.imagesCarousel}>
          {base64Images.length && !loading ? (
            <Carousel
              dynamicHeight
              centerSlidePercentage={40}
              emulateTouch
              infiniteLoop
              className={classes.imagesCarousel}
              autoPlay
            >
              {base64Images.map((image, index) => {
                return (
                  <div className={classes.image} key={index}>
                    <img
                      className={classes.img}
                      src={image.hash ? image.src : `data:image/png;base64, ${image.src}`}
                      alt={`${image.name}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = noImage;
                      }}
                    />
                    <p className={classes.imgName}>{image.name}</p>
                  </div>
                );
              })}
            </Carousel>
          ) : !loading ? (
            <p className={classes.noneText}>Nenhuma imagem adicionada</p>
          ) : (
            <CircularProgress size={24} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImagesCarousel;
