import React, { useState } from "react";
import {
  TextField,
  Button,
  InputAdornment,
  makeStyles,
  Paper,
  Typography
} from "@material-ui/core";

import NumberFormat from "react-number-format";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles(theme => ({
  investmentSimulator: {
    flex: 1,
    display: "flex",
    borderRadius: 16
  },
  investmentSimulatorContent: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: isMobile ? theme.spacing(1.5) : theme.spacing(3)
  },
  investmentSimulatorContentTitleArea: {
    flex: 1
  },
  investmentSimulatorContentTitle: {
    fontFamily: "Montserrat",
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  investmentSimulatorContentForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: isMobile ? theme.spacing(1) : theme.spacing(2)
  },
  textInput: {
    borderRadius: 12,
    borderColor: "#EFEFEF",
    borderWidth: 1,
    height: isMobile ? 30 : ""
  },
  investmentSimulatorContentFormButtons: {
    marginTop: theme.spacing(0.2)
  },
  button: {
    marginRight: theme.spacing(1)
  },
  buttonText: {
    fontFamily: "Montserrat",
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: isMobile ? 10 : ""
  }
}));

const InvestmentSimulator = ({ onSimulate, onCancel }) => {
  const classes = useStyles();

  const [investmentValue, setInvestmentValue] = useState("");
  const [autoFocusEnabled, setAutofocusEnabled] = useState(false);

  const NumberMoneyFormat = props => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value
            }
          });
        }}
        thousandSeparator="."
        decimalSeparator=","
        isNumericString
      />
    );
  };

  return (
    <Paper className={classes.investmentSimulator} elevation={3}>
      <div className={classes.investmentSimulatorContent}>
        <div className={classes.investmentSimulatorContentTitleArea}>
          <Typography
            className={classes.investmentSimulatorContentTitle}
            variant={isMobile ? "body2" : "body1"}
          >
            Simular investimento
          </Typography>
        </div>
        <div className={classes.investmentSimulatorContentInput}>
          <form className={classes.investmentSimulatorContentForm}>
            <TextField
              autoFocus={isMobile ? autoFocusEnabled : true}
              variant="outlined"
              value={investmentValue}
              onChange={event => setInvestmentValue(event.target.value)}
              onFocus={() => (isMobile ? setAutofocusEnabled(true) : null)}
              onBlur={() => (isMobile ? setAutofocusEnabled(false) : null)}
              InputProps={{
                classes: {
                  root: classes.textInput
                },
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
                inputComponent: NumberMoneyFormat
              }}
            />
            <div className={classes.investmentSimulatorContentFormButtons}>
              <Button
                className={classes.button}
                onClick={() => {
                  onSimulate(parseFloat(investmentValue));
                }}
                disabled={investmentValue === "" || investmentValue === "0"}
              >
                <Typography
                  className={classes.buttonText}
                  style={{
                    color:
                      investmentValue === "" || investmentValue === "0"
                        ? "gray"
                        : "#EFA900"
                  }}
                  variant="body2"
                >
                  Simular
                </Typography>
              </Button>
              <Button
                className={classes.button}
                onClick={() => {
                  if (investmentValue.length) {
                    onCancel();
                  }
                  setInvestmentValue("");
                }}
              >
                <Typography className={classes.buttonText} variant="body2">
                  Cancelar
                </Typography>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Paper>
  );
};

export default InvestmentSimulator;
