import React, { setGlobal } from 'reactn';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';

setGlobal({
  user: sessionStorage.getItem('user')
    ? JSON.parse(sessionStorage.getItem('user'))
    : {
        id: '',
        name: '',
        email: '',
        type: 'INVESTOR',
      },
  indications: null,
  projects: [],
  selectedProject: null,
  levels: [
    {
      level: 2,
      name: '2',
      min: 0,
      max: 0,
    },
    {
      level: 3,
      name: '3',
      min: 0,
      max: 300000,
    },
    {
      level: 4,
      name: '4',
      min: 0,
      max: 600000,
    },
    {
      level: 5,
      name: '5',
      min: 0,
      max: 1000000,
    },
  ],
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
