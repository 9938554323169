import React, { useEffect, useState, useRef } from 'react';

import { useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import { makeStyles, TextField, Button, Snackbar, IconButton, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';

import Loading from '@material-ui/core/CircularProgress';

import authService from '../../services/auth-service';

import logo from '../../assets/images/logo.png';
import loginBackground from '../../assets/images/login-background.png';
import { isMobile } from 'react-device-detect';
import DocumentNumber from '@react-br-forms/cpf-cnpj-mask';

const useStyles = makeStyles((theme) => ({
  login: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 5,
  },
  backgroundImage: {
    width: '100vw',
    height: '65vh',
    position: 'absolute',
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    zIndex: -1,
  },
  mainArea: {
    flex: 1,
    display: 'flex',
  },
  leftArea: {
    flex: 1,
  },
  centerArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  logoArea: {
    flex: 0.8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flex: 0.5,
    },
  },
  loginArea: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#FFF',
    borderRadius: 35,
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
  },
  loginForm: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loginAreaTitleArea: {
    flex: 0.5,
    display: 'flex',
    alignItems: 'flex-end',
  },
  loginAreaTitleText: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
  },
  loginInputs: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  inputField: {
    flex: 1,
    width: 300,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  inputFieldLabel: {
    color: '#A5A5A5',
    fontFamily: 'Montserrat',
    fontSize: 16,
  },
  inputFieldText: {
    fontFamily: 'Montserrat',
    fontSize: 14,
  },
  loginButtons: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  submitButton: {
    width: 150,
    height: 40,
    borderRadius: 16,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
    color: theme.palette.primary.main,
  },
  forgotPasswordArea: {
    flex: 0.5,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flex: 0.2,
    },
  },
  forgotPasswordText: {
    fontFamily: 'Montserrat',
    color: theme.palette.primary.light,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
    marginTop: theme.spacing(3),
  },
  recoveryPasswordTextArea: {
    marginTop: theme.spacing(2),
    width: 350,
  },
  recoveryPasswordText: {
    fontFamily: 'Montserrat',
    color: '#52575D',
    textAlign: 'center',
  },
  rightArea: {
    flex: 1,
  },
  snackbar: {
    backgroundColor: '#d32f2f',
  },
  snackbarContent: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarIcon: {
    marginRight: theme.spacing(1),
  },
}));

const WRONG_CREDENTIALS_MESSAGE = 'Usuário ou senha incorretos';

const Login = ({ history, location }) => {
  let { from } = location.state;
  if (['login', '/'].includes(from)) from = '';
  useEffect(() => {
    if (authService.isAuthenticated()) {
      history.push('/' + from ?? '');
    }
  }, []); //eslint-disable-line

  const classes = useStyles();
  const loginButton = useRef();

  const [, setUser] = useGlobal('user');
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [onLoginLoading, setLoginLoading] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(WRONG_CREDENTIALS_MESSAGE);
  const [recoveryPasswordOpen] = useState(false);

  const formattedDocument =
    process.env.REACT_APP_LOCAL_DATA_PROVIDER === 'true'
      ? document.replace(
          /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, //eslint-disable-line
          ''
        )
      : document;
  const login = async (event) => {
    event.preventDefault();
    setLoginLoading(true);
    loginButton.current.disabled = true;
    try {
      const { isAuthenticated, user } = await authService.authenticate(formattedDocument, password);

      if (isAuthenticated) {
        setUser(user);
        sessionStorage.setItem('user', JSON.stringify(user));
        history.push('/' + from ?? '');
      } else {
        setErrorMessage(WRONG_CREDENTIALS_MESSAGE);
        setErrorSnackbarOpen(true);
        loginButton.current.disabled = false;
      }
    } catch (error) {
      setErrorMessage('Erro ao acessar o servidor: ' + error.message);
      setErrorSnackbarOpen(true);
      loginButton.current.disabled = false;
    }
    setLoginLoading(false);
  };

  const recoveryPassword = async (event) => {
    event.preventDefault();
  };

  const buttonContent = () => {
    if (recoveryPasswordOpen) {
      return 'Enviar';
    } else if (onLoginLoading) {
      return <Loading />;
    } else {
      return 'Acessar';
    }
  };

  return (
    <div className={classes.login}>
      <div className={classes.backgroundImage}></div>
      <div className={classes.mainArea}>
        <div className={classes.leftArea}></div>
        <div className={classes.centerArea}>
          <div className={classes.logoArea}>
            <a href='https://ameaconstrutora.com.br'>
              <img src={logo} alt='Logo Amea' />
            </a>
          </div>
          <div className={classes.loginArea}>
            <form
              id='ameaLogin'
              autoComplete='on'
              className={classes.loginForm}
              onSubmit={recoveryPasswordOpen ? recoveryPassword : login}
            >
              <div className={classes.loginAreaTitleArea}>
                <Typography variant='h6' className={classes.loginAreaTitleText}>
                  {recoveryPasswordOpen ? 'Recuperar Senha' : 'Área do investidor'}
                </Typography>
              </div>
              <div className={classes.loginInputs}>
                {recoveryPasswordOpen ? (
                  <div className={classes.recoveryPasswordTextArea}>
                    <Typography vartiant='body2' className={classes.recoveryPasswordText}>
                      Digite seu email e nossa equipe entrará em contato com você para recuperar a sua senha
                    </Typography>
                  </div>
                ) : (
                  <TextField
                    name='username'
                    label='CPF/CNPJ'
                    className={classes.inputField}
                    value={document}
                    onChange={(event) => {
                      event.target.focus();
                      setDocument(event.target.value);
                    }}
                    InputLabelProps={{
                      className: classes.inputFieldLabel,
                    }}
                    InputProps={{
                      className: classes.inputFieldText,
                      inputComponent: DocumentNumber,
                    }}
                    required
                    autoFocus
                  />
                )}
                {recoveryPasswordOpen ? (
                  <TextField
                    name='email'
                    label='E-mail'
                    type='email'
                    className={classes.inputField}
                    style={{ width: isMobile ? 220 : 300 }}
                    value={recoveryEmail}
                    onChange={(event) => setRecoveryEmail(event.target.value)}
                    InputLabelProps={{
                      className: classes.inputFieldLabel,
                    }}
                    InputProps={{
                      className: classes.inputFieldText,
                    }}
                    required
                  />
                ) : (
                  <TextField
                    label='Senha'
                    name='password'
                    type='password'
                    className={classes.inputField}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    InputLabelProps={{
                      className: classes.inputFieldLabel,
                    }}
                    InputProps={{
                      className: classes.inputFieldText,
                    }}
                    required
                  />
                )}
              </div>
              <div className={classes.loginButtons}>
                <Button ref={loginButton} className={classes.submitButton} type='submit'>
                  {buttonContent()}
                </Button>
              </div>
            </form>
          </div>
          <div className={classes.forgotPasswordArea}>
            <div>
              {/* <Typography
                                className={classes.forgotPasswordText}
                                onClick={() =>
                                    setRecoveryPasswordOpen(
                                        !recoveryPasswordOpen
                                    )
                                }
                            >
                                {recoveryPasswordOpen
                                    ? "Login"
                                    : "Esqueceu sua senha?"}
                            </Typography> */}
            </div>
          </div>
        </div>
        <div className={classes.rightArea}></div>
      </div>

      <Snackbar
        autoHideDuration={3000}
        onClose={() => setErrorSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        ContentProps={{ classes: { root: classes.snackbar } }}
        open={errorSnackbarOpen}
        message={
          <span id='client-snackbar' className={classes.snackbarContent}>
            <ErrorIcon className={classes.snackbarIcon} />
            {errorMessage}
          </span>
        }
        action={[
          <IconButton key='close' aria-label='close' color='inherit' onClick={() => setErrorSnackbarOpen(false)}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </div>
  );
};

export default withRouter(Login);
