import Singleton from '../../lib/singleton';
import config from '../../config/app';
import localAuthProvider from './local-auth-provider';
import remoteAuthProvider from './remote-auth-provider';
class AuthService extends Singleton {
  constructor() {
    super();
    this.config = config;
  }

  getAuthProvider = () => {
    if (!this._authProvider) {
      if (config.useLocalDataProvider) {
        this._authProvider = localAuthProvider;
      } else {
        this._authProvider = remoteAuthProvider;
      }
    }
    return this._authProvider;
  };

  authenticate = async (username, password) => {
    this.unauthenticated();
    const { authenticated, accessToken, user } = await this.getAuthProvider().authenticate(username, password);

    if (authenticated) {
      sessionStorage.setItem('authenticated', 'true');
      sessionStorage.setItem('accessToken', accessToken);
    }

    const response = {
      isAuthenticated: this.isAuthenticated(),
      user,
    };

    return response;
  };

  isAuthenticated = () => {
    const authenticated = sessionStorage.getItem('authenticated') === 'true';
    return authenticated;
  };

  unauthenticated() {
    sessionStorage.setItem('authenticated', false);
    sessionStorage.setItem('currentUser', '');
    sessionStorage.setItem('accessToken', '');
    sessionStorage.setItem('userId', '');
    sessionStorage.setItem('user', JSON.stringify({}));
  }
}

export default new AuthService();
