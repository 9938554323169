import Singleton from '../../lib/singleton';
import config from '../../config/app';
class DataService extends Singleton {
  cache = {};

  loadDataProviderConfig = async () => {
    if (config.useLocalDataProvider) {
      this._dataProvider = (await import('./local-data-provider')).default;
    } else {
      this._dataProvider = (await import('./remote-data-provider')).default;
    }
  };

  getDataProvider = async () => {
    if (!this._dataProvider) {
      await this.loadDataProviderConfig();
    }
    return this._dataProvider;
  };

  isCached = (resource) => {
    const data = this.cache[resource];
    const now = new Date();

    const interval = config.cacheInterval;

    if (data && data.fetchedAt) {
      const fetchedAt = data.fetchedAt.getTime();
      const elapsed = now.getTime() - fetchedAt;
      return elapsed < interval;
    }

    return false;
  };

  create = async (resource, data) => {
    const dataProvider = await this.getDataProvider();
    const result = await dataProvider('CREATE', resource, { data });
    return result ? result.data : null;
  };

  deleteOne = async (resource, id) => {
    const dataProvider = await this.getDataProvider();
    return await dataProvider('DELETE', resource, { id });
  };

  fetchOne = async (resource, id) => {
    const dataProvider = await this.getDataProvider();
    const result = await dataProvider('GET_ONE', resource, { id });
    return result ? result : null;
  };

  fetchList = async (resource, filter = {}) => {
    const dataProvider = await this.getDataProvider();
    const result = await dataProvider('GET_LIST', resource, {
      filter,
    });
    return result ? result : null;
  };

  update = async (resource, data) => {
    const dataProvider = await this.getDataProvider();
    const result = await dataProvider('UPDATE', resource, {
      id: data.id,
      data,
    });
    return result ? result.data : null;
  };

  fetchAllProjects = async () => {
    return await this.fetchList('projects');
  };
  fetchProject = async (projectId) => {
    let project;
    project = await this.fetchOne('projects', projectId);
    return project;
  };
  fetchDocument = async (path) => {
    return await this.fetchOne('documents', path);
  };
  fetchHashDocument = async (path) => {
    return await this.fetchOne('documents/hash', path);
  };

  fetchSimulation = async (data) => {
    return await this.fetchOne('simulations', data);
  };
}

export default new DataService();
