import React, { useState, useEffect } from 'react';
import Paginate from 'react-paginate';
import config from '../../config/app';

import {
  Dialog,
  DialogContent,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  IconButton,
  TableContainer,
  Snackbar,
  CircularProgress,
} from '@material-ui/core';

import eventService from '../../services/event-service';
import EventTypes from '../../constants/event-types';
import dataService from '../../services/data-service';

import FileIcon from 'react-file-icon';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import NextIcon from '@material-ui/icons/ArrowForwardIos';

import DownloadIcon from '@material-ui/icons/GetApp';
// import ViewIcon from '@material-ui/icons/Search';
const useStyles = makeStyles((theme) => ({
  documentsList: {},
  listItem: {},
  tableHead: {
    backgroundColor: '#E3E9EF',
  },
  tableCellTitle: {
    color: theme.palette.primary.main,
    fontFamily: 'Montserrat',
    fontWeight: 600,
  },
  tableCellText: {
    fontFamily: 'Montserrat',
    fontSize: 13,
  },
  tableRow: {
    height: 10,
  },
  noneText: {
    fle: 1,
    textAlign: 'center',
  },
  '@global .activePage': {
    backgroundColor: '#508FF4',
    borderRadius: '50px',
    padding: '5px 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  '@global .activePage a': {
    color: '#fbfbfb !important',
  },

  '@global .paginate p': {
    textAlign: 'center',
    margin: '15px 0px',
  },

  '@global .paginate > ul': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '25px',
    marginBottom: '35px',
    listStyle: 'none',
    alignItems: 'center',
  },
  '@global .paginate > ul > li': {
    boxSizing: 'border-box',
    marginRight: '25px',
    cursor: 'pointer',
  },
  '@global .paginate > ul > li > a': {
    boxSizing: 'border-box',
    color: '#52575D',
    outline: 0,
    fontWeight: 'bold',
  },
  '@global .previous button, .next button': {
    outline: 0,
    borderRadius: '50px',
    backgroundColor: 'transparent',
    border: 'none',
    width: '35px',
    height: '35px',
  },
  '@global .disabled-button svg': {
    fill: '#E3E9EF !important',
    cursor: 'pointer',
  },
  snackbar: {
    backgroundColor: '#d32f2f',
  },
  snackbarContent: {
    display: 'flex',
    alignItems: 'center',
  },
  snackbarIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Documents = ({ documents, nfs, projects }) => {
  const classes = useStyles();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingKey, setLoadingKey] = useState(0);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const [filesPageCount, setFilesPageCount] = useState(0);
  const [rowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  useEffect(() => {
    eventService.addListener(EventTypes.OPEN_DOCUMENTS_DIALOG, (selectedType) => {
      setDialogOpen(true);
      setPage(0);
      switch (selectedType) {
        case 'documents':
          setFiles(documents);
          break;
        case 'nfs':
          setFiles(nfs);
          break;
        case 'projects':
          setFiles(projects);
          break;
        default:
          break;
      }
    });
  }, [documents, nfs, projects]);

  useEffect(() => {
    if (files) {
      setFilesPageCount(files.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length);
    }
  }, [page, files, rowsPerPage]);

  const getExtensionColor = (extension) => {
    switch (extension) {
      case 'pdf':
        return 'red';
      default:
        return 'gray';
    }
  };

  const downloadFile = async (file, index) => {
    setLoading(true);
    setLoadingKey(index);
    try {
      if (file.hash) {
        window.open(`${config.documentHashURL}${file.hash}`);
      } else {
        const documentToDownload = await dataService.fetchDocument(file.path);
        const pdfWindow = window.open('');
        pdfWindow.document.write(
          `<html<head><title>${documentToDownload.filename}</title><style>body{margin: 0px;}iframe{border-width: 0px;}</style></head>`
        );
        pdfWindow.document.write(
          `<body><embed width='100%' height='100%' src='data:application/pdf;base64,${encodeURI(
            documentToDownload.content
          )}#toolbar=0&navpanes=0&scrollbar=0'></embed></body></html>`
        );
      }
    } catch (err) {
      console.error(err);
      setErrorSnackbarOpen(true);
    }
    setLoading(false);
    setLoadingKey(0);
  };

  const handleChangePage = (newPage) => {
    setFilesPageCount(0);
    setPage(newPage);
  };

  return (
    <Dialog
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
      className={classes.documentsDialog}
      maxWidth='md'
      fullWidth
    >
      <DialogContent className={classes.documentsDialogContent}>
        <TableContainer>
          <Table size='small'>
            <TableHead className={classes.tableHead}>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableCellTitle} align='center'>
                  Upload
                </TableCell>
                <TableCell className={classes.tableCellTitle} align='center'>
                  Tipo de arquivo
                </TableCell>
                {/* <TableCell className={classes.tableCellTitle} align='center'>
                  Visualizar
                </TableCell> */}
                <TableCell className={classes.tableCellTitle} align='center'>
                  Download
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files?.length ? (
                files.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file, index) => (
                  <TableRow key={index} className={classes.tableRow}>
                    <TableCell align='center'>
                      <Typography variant='body2' className={classes.tableCellText}>
                        {file.name}
                      </Typography>
                    </TableCell>
                    <TableCell align='center'>
                      <FileIcon
                        extension={file.name.split('.').pop()}
                        size={15}
                        color={getExtensionColor(file.name.split('.').pop())}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      {loading && loadingKey === index ? (
                        <IconButton>
                          <CircularProgress size={24} />
                        </IconButton>
                      ) : (
                        <IconButton disabled={loading} onClick={() => downloadFile(file, index)}>
                          <DownloadIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4}>
                    <p className={classes.noneText}>Nenhum valor encontrado</p>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className='paginate'>
          <p>
            <b>Exibindo:</b> {files ? filesPageCount : 0} de {files ? files.length : 0}
          </p>
          <Paginate
            pageCount={files ? Math.ceil(files.length / rowsPerPage) : 1}
            marginPagesDisplayed={4}
            onPageChange={(selectedItem) => handleChangePage(selectedItem.selected)}
            previousLabel={
              <button>
                <BackIcon color='disabled' />
              </button>
            }
            nextLabel={
              <button>
                <NextIcon color='disabled' />
              </button>
            }
            activeClassName='activePage'
            disabledClassName='disabled-button'
          />
        </div>
      </DialogContent>
      <Snackbar
        autoHideDuration={3000}
        onClose={() => setErrorSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        ContentProps={{ classes: { root: classes.snackbar } }}
        open={errorSnackbarOpen}
        message={
          <span id='client-snackbar' className={classes.snackbarContent}>
            <ErrorIcon className={classes.snackbarIcon} />
            {'Arquivo não encontrado para download'}
          </span>
        }
        action={[
          <IconButton key='close' aria-label='close' color='inherit' onClick={() => setErrorSnackbarOpen(false)}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Dialog>
  );
};

export default Documents;
