import React from 'react';
import { Dialog, DialogContent, makeStyles, Typography, DialogTitle, IconButton } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import eventService from '../../services/event-service';
import EventTypes from '../../constants/event-types';

import CloseIcon from '@material-ui/icons/Close';

import ProgressIndicator from '../Construction-progress/ProgressIndicator';

const useStyles = makeStyles((theme) => ({
  projectProgressArea: {
    minHeight: '30vh',
  },
  projectProgressAreaContent: {},
  projectProgressIndicators: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {},
  },
  projectProgressIndicatorsTitleArea: {
    flex: 1,
  },
  projectProgressIndicatorsTitle: {
    fontFamily: 'Montserrat',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  projectProgressIndicatorsTooltips: {
    display: 'grid',
    'grid-template-columns': 'repeat(4, 1fr)',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      'grid-template-columns': 'auto',
    },
  },
  progressIndicatorTooltip: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  noneText: {
    textAlign: 'center',
  },
}));

const ProjectProgressArea = ({ phasesProgress = [] }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    eventService.addListener(EventTypes.OPEN_PROJECT_PROGRESS_AREA_DIALOG, () => {
      setOpen(true);
    });
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.projectProgressArea}
      fullWidth
      maxWidth={window.screen.width <= 1280 ? 'md' : 'lg'}
    >
      <DialogTitle disableTypography>
        <IconButton
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.projectProgressAreaContent}>
        {phasesProgress.length ? (
          <div className={classes.projectProgressIndicators}>
            <div className={classes.projectProgressIndicatorsTitleArea}>
              <Typography className={classes.projectProgressIndicatorsTitle}>
                Confira abaixo os cronogramas da obra:
              </Typography>
            </div>
            <div className={classes.projectProgressIndicatorsTooltips}>
              {phasesProgress.map((projectPhase, index) => (
                <div className={classes.progressIndicatorTooltip} key={index}>
                  <ProgressIndicator
                    key={index}
                    percentage={Math.round(projectPhase.progress)}
                    title={projectPhase.phase}
                    description={projectPhase.description}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Typography className={classes.noneText}>Sem cronogramas</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ProjectProgressArea;
