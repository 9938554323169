import React from 'react';
import { useState } from 'react';

import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  AreaChart,
  ResponsiveContainer,
  BarChart,
  Bar,
} from 'recharts';
import { useEffect } from 'react';
import { makeStyles, Typography, CircularProgress } from '@material-ui/core';

import currencyFormatter from 'currency-formatter';

import translatedResources from '../../constants/translatedResources';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    marginLeft: isMobile ? theme.spacing(1) : theme.spacing(4),
  },
  title: {
    fontFamily: 'Montserrat',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  chartLegend: {
    fontFamily: 'Montserrat',
    color: '#898D91',
    fontSize: 12,
  },
  loadingDiv: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  noneText: {
    width: '100%',
    marginTop: '20%',
    textAlign: 'center',
  },
}));

const Chart = ({
  data = null,
  chartType,
  chartTitle,
  chartColors,
  xPercent = false,
  yPercent = false,
  yMoney = false,
  yMax,
  width,
  height,
}) => {
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);
  const [xTicks, setXTicks] = useState([0]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      updateChart(data);
    } else {
      setIsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const ticks = [];
    chartData.map((data) => ticks.push(data.x));

    setXTicks(ticks);
  }, [chartData]);

  const chartTypes = {
    area: (
      <ResponsiveContainer minWidth={width} height={height}>
        <AreaChart data={chartData} margin={{ right: isMobile ? 50 : 30, left: isMobile ? -20 : 0 }}>
          <Legend
            verticalAlign='top'
            align='left'
            formatter={(legend) =>
              translatedResources[legend] ? (
                <span className={classes.chartLegend}>{translatedResources[legend]}</span>
              ) : (
                <span className={classes.chartLegend}>{legend}</span>
              )
            }
            iconType='rect'
            wrapperStyle={{ marginLeft: 35 }}
            height={isMobile ? 50 : 35}
          />
          <Tooltip
            formatter={(tooltipValue, tooltipName) => [
              yMoney
                ? currencyFormatter.format(tooltipValue, { code: 'BRL' })
                : yPercent
                ? `${tooltipValue}%`
                : tooltipValue,
              translatedResources[tooltipName] ? translatedResources[tooltipName] : tooltipName,
            ]}
            labelFormatter={(label) => (xPercent ? `${label}%` : label)}
          />
          <XAxis
            dataKey='x'
            type={xPercent ? 'number' : 'category'}
            tick={{ fontSize: 9, fontFamily: 'Montserrat', paddingLeft: -10 }}
            tickFormatter={(tick) => (xPercent ? `${tick}%` : tick)}
            ticks={xPercent ? [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100] : xTicks}
            interval={isMobile ? 2 : 0}
            width={50}
          />
          <YAxis
            tick={{ fontSize: 9, fontFamily: 'Montserrat' }}
            tickFormatter={(tick) => (yMoney ? tick / 1000 : yPercent ? `${tick}%` : tick)}
            domain={yPercent ? [0, 100] : yMoney ? [0, Math.round(yMax / 1000) * 1000] : ''}
            type='number'
            ticks={yPercent ? [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100] : ''}
            interval={0}
            tickCount={10}
          />
          <CartesianGrid strokeLinecap='3 3' />
          {data
            ? Object.keys(data).map((chart, index) => {
                return (
                  <Area
                    key={index}
                    type='monotone'
                    dataKey={chart}
                    stroke={chartColors[index]}
                    fill={chartColors[index]}
                    dot={{ fill: '#FFF' }}
                    animationDuration={2500}
                    animationBegin={1000}
                  />
                );
              })
            : null}
        </AreaChart>
      </ResponsiveContainer>
    ),
    bar: (
      <ResponsiveContainer minWidth={width} minHeight={height}>
        <BarChart data={chartData} margin={{ right: isMobile ? 50 : 30, left: isMobile ? -20 : '' }}>
          <Legend
            verticalAlign='top'
            align='left'
            formatter={(legend) =>
              translatedResources[legend] ? (
                <span className={classes.chartLegend}>{translatedResources[legend]}</span>
              ) : (
                <span className={classes.chartLegend}>{legend}</span>
              )
            }
            iconType='rect'
            wrapperStyle={{ marginLeft: 35 }}
            height={isMobile ? 120 : 60}
          />
          <Tooltip
            formatter={(tooltipValue, tooltipName) => [
              yMoney
                ? currencyFormatter.format(tooltipValue, { code: 'BRL' })
                : yPercent
                ? `${tooltipValue}%`
                : tooltipValue,
              translatedResources[tooltipName] ? translatedResources[tooltipName] : tooltipName,
            ]}
          />
          <XAxis
            dataKey='x'
            type={xPercent ? 'number' : 'category'}
            tick={{ fontSize: 9, fontFamily: 'Montserrat', paddingLeft: -10 }}
            tickFormatter={(tick) => (xPercent ? `${tick}%` : tick)}
            ticks={xPercent ? [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100] : xTicks}
            interval={isMobile ? 2 : 0}
            width={50}
          />
          <YAxis
            tick={{ fontSize: 9, fontFamily: 'Montserrat' }}
            tickFormatter={(tick) => (yMoney ? tick / 1000 : yPercent ? `${tick}%` : tick)}
            domain={yPercent ? [0, 100] : yMoney ? (yMax !== 0 ? [0, Math.round(yMax / 1000) * 1000] : [0, 10000]) : ''}
            type='number'
            ticks={yPercent ? [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100] : ''}
            interval={0}
            tickCount={10}
          />
          <CartesianGrid strokeLinecap='3 3' />
          {data
            ? Object.keys(data).map((chart, index) => {
                return (
                  <Bar
                    key={index}
                    type='monotone'
                    dataKey={chart}
                    stroke={chartColors[index]}
                    fill={chartColors[index]}
                    animationDuration={1500}
                    animationBegin={1000}
                  />
                );
              })
            : null}
        </BarChart>
      </ResponsiveContainer>
    ),
  };

  const updateChart = (receivedData) => {
    const chartData = [];
    const chartType = Object.keys(receivedData)[0];

    receivedData[Object.keys(receivedData)[0]].map((data) => {
      const newDataEntry = {};

      newDataEntry.x = chartType === 'projectProgress' ? data.x : parseFloat(data.x).toFixed(2);
      chartData.push(newDataEntry);
      return null;
    });
    Object.keys(receivedData).map((data) => {
      receivedData[data].map((dataPoints, index) => {
        if (dataPoints.y !== null) {
          chartData[index][data] = dataPoints.y?.perc_fisico_executado
            ? parseFloat(dataPoints.y.perc_fisico_executado).toFixed(2)
            : parseFloat(dataPoints.y).toFixed(2);
        }
        return null;
      });
      return null;
    });

    setChartData(chartData);
    setIsLoading(false);
  };

  return chartData.length ? (
    <div className={classes.chartContainer}>
      <div className={classes.titleArea}>
        <Typography className={classes.title} variant='body2'>
          {chartTitle}
        </Typography>
      </div>
      <div className={classes.chartArea}>{chartTypes[chartType]}</div>
    </div>
  ) : isLoading ? (
    <div className={classes.loadingDiv}>
      <CircularProgress size={20} />
    </div>
  ) : (
    <div className={classes.chartContainer}>
      <div className={classes.titleArea}>
        <Typography className={classes.title} variant='body2'>
          {chartTitle}
        </Typography>
      </div>
      <div className={classes.noneText}>
        <p>Sem dados</p>
      </div>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  return prevProps === nextProps;
};

export default React.memo(Chart, areEqual);
