import React, { useState, useEffect } from 'react';
import { Select, MenuItem, makeStyles, Typography } from '@material-ui/core';
import UserViews from '../../../constants/user-views';
import UserViewController from '../../../controllers/UserViewController';

const useStyles = makeStyles((theme) => ({
  userTypeSelectionArea: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  userTypeSelect: {
    color: theme.palette.secondary.main,
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 15,
    width: 140,
  },
  userTypeSelectIcon: {
    fill: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
  userTypeSelectMenu: {
    marginTop: theme.spacing(6),
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 15,
    borderWidth: 1,
  },
  selectMenuItemText: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
  },
}));

const NavbarSelect = ({ user, history }) => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState(UserViews.INVESTOR);

  useEffect(() => {
    UserViewController.onLoadViewHandler((userView) => {
      setSelectedType(userView);
    });
  }, []);

  const handleSelectChange = (event) => {
    const userView = event.target.value;
    UserViewController.onSelectViewHandler(userView, history);
    setSelectedType(userView);
  };

  return user.type === 'INVESTOR/REFERRER' ? (
    <Select
      variant='outlined'
      value={selectedType}
      onChange={handleSelectChange}
      classes={{
        root: classes.userTypeSelect,
        outlined: classes.userTypeSelectOutlined,
        icon: classes.userTypeSelectIcon,
      }}
      MenuProps={{ classes: { paper: classes.userTypeSelectMenu } }}
      style={{ border: '1px solid #EFA900', height: 45 }}
    >
      <MenuItem value='INVESTOR' style={{ display: selectedType === 'INVESTOR' ? 'none' : '' }}>
        <Typography className={classes.selectMenuItemText}>Investidor</Typography>
      </MenuItem>
      <MenuItem value='REFERRER' style={{ display: selectedType === 'REFERRER' ? 'none' : '' }}>
        <Typography className={classes.selectMenuItemText}>Indicador</Typography>
      </MenuItem>
    </Select>
  ) : (
    <div></div>
  );
};

export default NavbarSelect;
