import React from 'react';
import { useGlobal } from 'reactn';
import Investor from '../Investor';
import Referrer from '../Referrer';
import { useEffect } from 'react';

const Screens = {
  INVESTOR: <Investor />,
  REFERRER: <Referrer />,
  'INVESTOR/REFERRER': <Investor />,
};

const Main = () => {
  const [user, setUser] = useGlobal('user');

  useEffect(() => {
    if (!user.id) {
      setUser(JSON.parse(sessionStorage.getItem('user')));
    }
  }, [user]); //eslint-disable-line

  return Screens[user.type || 'INVESTOR'];
};

export default Main;
