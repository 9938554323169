import roundCurrency from '../lib/round-currency';
import dataService from '../services/data-service';

const LEVEL_PRECISION = 2;

export function getCurrentLevel(levels, totalIndications) {
  let currentLevel = levels[1];
  totalIndications = roundCurrency(totalIndications);

  if (levels) {
    const levelInfo = Object.values(levels)
      .slice(1) // skip the first level, by default (business rule)
      .reverse()
      .find((level) => totalIndications >= roundCurrency(level.min));
    if (levelInfo) currentLevel = levelInfo;
  }

  return currentLevel;
}

export const fetchLevels = async () => {
  const formatLevel = (level) => {
    return typeof level === 'string' ? parseFloat(level).toFixed(LEVEL_PRECISION) : level?.toFixed(LEVEL_PRECISION);
  };
  try {
    const levels = await dataService.fetchList('indication-levels');
    const filtered = Object.values(levels).map((level, order) => ({
      ...level,
      order,
      level: formatLevel(level.level),
    }));
    return filtered;
  } catch (err) {
    console.error(err);
  }
  return null;
};

export const fetchIndications = async ({ userId }) => {
  try {
    const indications = await dataService.fetchList('indications');
    const filtered = Object.values(indications).find((indication) => indication.userId == userId);
    return filtered;
  } catch (err) {
    console.error(err);
  }
  return null;
};

function getLevelTitle(level) {
  let levelTitle = level?.level;
  if (!levelTitle) return '';
  if (typeof levelTitle === 'string') {
    levelTitle = parseFloat(levelTitle);
  }
  levelTitle = levelTitle.toFixed(2) + '%';
  return levelTitle;
}

function getPercentage({ currentLevel, level, totalIndications, index }) {
  if (!currentLevel || !level) return '';
  if (index === 0 || currentLevel.order >= level.order) {
    return 100;
  }
  return Math.round((totalIndications * 100) / level.min);
}

export default {
  getCurrentLevel,
  fetchLevels,
  fetchIndications,
  getLevelTitle,
  getPercentage,
};
