import React, { useEffect, useState } from "react";
import { Line } from "rc-progress";
import { makeStyles, Typography } from "@material-ui/core";

import currencyFormatter from "currency-formatter";

import { isMobile } from "react-device-detect";

const useStyles = makeStyles(theme => ({
  projectInputIndicator: {
    width: 400,
    display: "flex",
    flexDirection: "column"
  },
  projectInputTextArea: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(0.5)
  },
  projectInputText: {
    fontFamily: "Montserrat",
    fontWeight: 600,
    color: "#52575D"
  },
  projectInputTextValueArea: {
    display: "flex",
    alignItems: "center"
  },
  indicatorLine: {
    borderRadius: 10,
    height: 9
  },
  projectInputInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(1)
  }
}));

const ProjectInputIndicator = ({ value, percent }) => {
  const classes = useStyles();

  const [projectInputPercent, setProjectInputPercent] = useState(0);

  useEffect(() => {
    setProjectInputPercent(percent);
  }, [percent]);

  return (
    <div
      className={classes.projectInputIndicator}
      style={{ width: isMobile ? 300 : 400 }}
    >
      <div
        className={classes.projectInputTextArea}
        style={{
          flexDirection: isMobile ? "column" : "row",
          justifyContent: isMobile ? "center" : "space-between",
          alignItems: isMobile ? "flex-start" : ""
        }}
      >
        <div style={{ display: isMobile ? "flex" : "" }}>
          <Typography
            className={classes.projectInputText}
            variant="body1"
            style={{ marginRight: isMobile ? 5 : 0 }}
          >
            Valor aportado:
          </Typography>
          <Typography
            className={classes.projectInputText}
            style={{ marginTop: isMobile ? 0 : -5 }}
            variant="body1"
          >
            {currencyFormatter.format(value, { code: "BRL" })}
          </Typography>
        </div>
        <div
          className={classes.projectInputTextValueArea}
          style={{ alignItems: isMobile ? "flex-start" : "center" }}
        >
          <Typography className={classes.projectInputText} variant="body1">
            {projectInputPercent.toFixed(2)}%
          </Typography>
        </div>
      </div>
      <Line
        percent={[projectInputPercent]}
        strokeWidth="1"
        strokeColor="#EFA900"
        trailColor="rgba(230, 238, 247)"
        className={classes.indicatorLine}
        style={{ height: isMobile ? 10 : 9, width: isMobile ? 300 : "" }}
        transition={
          "stroke-dashoffset 5s ease 1s, stroke-dasharray 5s ease 1s, stroke 5s linear"
        }
      />
    </div>
  );
};

export default ProjectInputIndicator;
