import React from "react";
import { makeStyles, Menu, MenuItem, Typography } from "@material-ui/core";

import WebsiteIcon from "@material-ui/icons/Language";
import ExitIcon from "@material-ui/icons/ExitToApp";

const useStyles = makeStyles(theme => ({
  userMenu: {},
  menuItem: {
    flex: 1,
    display: "flex"
  },
  menuItemLogoArea: {
    flex: 0.4
  },
  menuItemTextArea: {
    flex: 1,
    display: "flex",
    alignItems: "center"
  },
  menuItemText: {
    fontFamily: "Montserrat",
    color: theme.palette.primary.main
  },
  menuItemList: {}
}));

const NavbarMenu = ({ anchorEl, setAnchorEl, onLogout }) => {
  const classes = useStyles();

  return (
    <Menu
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      keepMounted
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      className={classes.userMenu}
      classes={{ list: classes.menuItemList }}
    >
      {/* <MenuItem
              style={{ width: "100%", borderBottom: "1px solid #C4C8D066" }}
            >
              <div className={classes.menuItem}>
                <div className={classes.menuItemLogoArea}>
                  <ProfileIcon color="secondary" />
                </div>
                <div className={classes.menuItemTextArea}>
                  <Typography className={classes.menuItemText} variant="body2">
                    Perfil
                  </Typography>
                </div>
              </div>
            </MenuItem> */}
      <MenuItem
        onClick={() =>
          (window.location.href = "https://ameaconstrutora.com.br")
        }
        style={{ width: "100%", borderBottom: "1px solid #C4C8D066" }}
      >
        <div className={classes.menuItem}>
          <div className={classes.menuItemLogoArea}>
            <WebsiteIcon color="secondary" />
          </div>
          <div className={classes.menuItemTextArea}>
            <Typography
              className={classes.menuItemText}
              variant="body2"
              style={{ marginLeft: 10 }}
            >
              Ir para o Site
            </Typography>
          </div>
        </div>
      </MenuItem>
      <MenuItem onClick={onLogout}>
        <div className={classes.menuItem}>
          <div className={classes.menuItemLogoArea}>
            <ExitIcon color="secondary" />
          </div>
          <div className={classes.menuItemTextArea}>
            <Typography className={classes.menuItemText} variant="body2">
              Sair
            </Typography>
          </div>
        </div>
      </MenuItem>
    </Menu>
  );
};

export default NavbarMenu;
