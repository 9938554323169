import React from "react";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  footer: {
    height: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.secondary.dark
  },
  footerText: {
    fontFamily: "Montserrat",
    color: theme.palette.primary.main
  }
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Typography variant="body2" className={classes.footerText}>
        {new Date().getFullYear()} &copy; Todos os direitos reservados - Amea
      </Typography>
    </div>
  );
};
export default Footer;
