import React from 'react';
import { makeStyles, Typography, Paper, CircularProgress } from '@material-ui/core';

import Documents from '../Documents/Documents';
import Chart from '../Charts';

import currencyFormatter from 'currency-formatter';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ProjectProgressArea from '../Project-progress-area/ProjectProgressArea';
import ImagesCarousel from '../Images-carousel/ImagesCarousel';
import OptionsMenu from '../Navbar/Options-Menu/OptionsMenu';
import ProjectInputIndicator from '../Construction-progress/ProjectInputIndicator';

const useStyles = makeStyles((theme) => ({
  mobileInvestor: {
    minHeight: '80vh',
    display: 'flex',
    overflowX: 'hidden',
  },
  centerArea: {
    flex: 1,
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  },
  topArea: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  projectNameArea: {
    flex: 0.3,
    display: 'flex',
    alignItems: 'center',
  },
  projectNameText: {
    fontFamily: 'Montserrat',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  projectInputArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  projectInputInfo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  projectInputInfoTitle: {
    fontFamily: 'Montserrat',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  projectInputInfoValues: {
    fontFamily: 'Montserrat',
    color: '#52575D',
    fontWeight: 'bold',
  },
  projectInputIndicator: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  bottomArea: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    flex: 1,
  },
  chartsArea: {
    width: 400,
    height: 300,
  },
  chart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0.3),
  },
  chartsCarousel: {},
  carouselSlider: {},
  carouselSlide: {},
  chartPaper: {
    width: 350,
    padding: theme.spacing(1),
  },

  centerAreaBottom: {
    flex: 0.05,
  },

  changingProjectArea: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -theme.spacing(3),
  },
}));

const MobileInvestor = ({
  projects = [],
  selectedProject = {},
  setSelectedProject = null,
  isChangingProject = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.mobileInvestor}>
      <div className={classes.centerArea}>
        <div className={classes.topArea}>
          <div className={classes.projectNameArea}>
            <Typography className={classes.projectNameText} variant='h5'>
              {selectedProject?.name}
            </Typography>
          </div>
          {!isChangingProject ? (
            <div className={classes.projectInputArea}>
              <div className={classes.projectInputInfo}>
                <Typography className={classes.projectInputInfoTitle} variant='h6'>
                  Aporte Financeiro:
                </Typography>
                <Typography className={classes.projectInputInfoValues} variant='body2'>
                  Valor total estimado:{' '}
                  {currencyFormatter.format(selectedProject?.totalInput, {
                    code: 'BRL',
                  })}
                </Typography>
              </div>
              <div className={classes.projectInputIndicator}>
                <ProjectInputIndicator
                  value={selectedProject?.currentInput}
                  percent={
                    selectedProject?.currentInput && selectedProject?.totalInput
                      ? (100 * selectedProject?.currentInput) / selectedProject?.totalInput
                      : 0
                  }
                />
              </div>
            </div>
          ) : (
            <div className={classes.changingProjectArea}>
              <CircularProgress />
            </div>
          )}
        </div>
        {!isChangingProject ? (
          <>
            <div className={classes.bottomArea}>
              <div className={classes.chartsArea}>
                <Slider dots={true} slidesToShow={1} slidesToScroll={1} infinite={true} speed={500}>
                  <div className={classes.chart}>
                    <Paper className={classes.chartPaper}>
                      <Chart
                        chartTitle={'Cronograma físico-financeiro'}
                        data={selectedProject?.charts?.financialPhysicalSchedule}
                        xPercent
                        chartType='area'
                        yMoney
                        chartColors={['#39B6FF']}
                        yMax={selectedProject?.currentInput}
                        width={350}
                        height={175}
                      />
                    </Paper>
                  </div>
                  <div className={classes.chart}>
                    <Paper className={classes.chartPaper}>
                      <Chart
                        chartTitle={'Cronograma da obra'}
                        data={selectedProject?.charts?.projectSchedule}
                        yPercent
                        chartType='area'
                        chartColors={['#fdd365']}
                        width={350}
                        height={175}
                      />
                    </Paper>
                  </div>
                </Slider>
              </div>
            </div>
            <div className={classes.centerAreaBottom}></div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div style={{ position: 'absolute' }}>
        <OptionsMenu projects={projects} selectedProject={selectedProject} setSelectedProject={setSelectedProject} />
        <Documents
          documents={selectedProject?.documents?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))}
          nfs={selectedProject?.nfs?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))}
          projects={selectedProject?.projects?.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))}
        />
        <ImagesCarousel images={selectedProject?.images} />
        <ProjectProgressArea phasesProgress={selectedProject?.phasesProgress} />
      </div>
    </div>
  );
};

export default MobileInvestor;
