import EventTypes from '../constants/event-types';
import eventService from '../services/event-service';

export function onLoadViewHandler(viewHandler) {
  eventService.addListener(EventTypes.LOAD_VIEW, (userView) => {
    viewHandler?.(userView);
  });
}

export function onSelectViewHandler(userView, history) {
  if (userView === 'INVESTOR') {
    history.push('/investidor');
  } else {
    if (userView === 'REFERRER') {
      history.push('/indicador');
    }
  }
}

export function loadView(userView) {
  eventService.emitEvent(EventTypes.LOAD_VIEW, [userView]);
}

export default {
  onLoadViewHandler,
  onSelectViewHandler,
  loadView,
};
