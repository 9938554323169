const translatedResources = {
  services: 'Mão de obra',
  materials: 'Materiais',
  costs: 'Custos',
  indications: 'Indicações (em millhares de Reais)',
  accumulated: 'Acumulado (em millhares de Reais)',
  sales: 'Vendas (em millhares de Reais)',
  'account money': 'Saldo:',
  projectProgress: 'Progresso da obra',
};

export default translatedResources;
