import Referrer from '../models/Referrer';

export const fetchLevels = async () => {
  return await Referrer.fetchLevels();
};

export const fetchIndications = async ({ userId }) => {
  return await Referrer.fetchIndications({ userId });
};

export function onTotalIndicationsChangeHandler({ totalIndications, levels }) {
  const currentLevel = Referrer.getCurrentLevel(levels, totalIndications);
  return currentLevel;
}

export default {
  fetchLevels,
  fetchIndications,
  onTotalIndicationsChangeHandler,
};
