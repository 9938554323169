import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { makeStyles, IconButton } from '@material-ui/core';

import ProfileIcon from '@material-ui/icons/AccountCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';

import logo from '../../assets/images/logo.png';
import NavbarMenu from './Navbar-Menu/NavbarMenu';
import { withRouter } from 'react-router-dom';
import authService from '../../services/auth-service';
import NavbarSelect from './Navbar-Select/NavbarSelect';
import eventService from '../../services/event-service';
import EventTypes from '../../constants/event-types';

const useStyles = makeStyles((theme) => ({
  mobileNavbar: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
  },
  leftArea: {
    flex: 0.1,
  },
  centerArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  firstLine: {
    flex: 1,
    display: 'flex',
  },
  logoArea: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mobileLogo: {
    width: '70%',
    margin: 'auto',
    display: 'block',
  },
  userArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  iconButton: {
    color: '#FFF',
  },
  secondLine: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    margin: '2vh 0px',
  },
  userTypeArea: {
    display: 'flex',
  },
  menuArea: {},

  rightArea: {
    flex: 0.1,
  },
}));

const MobileNavbar = ({ history }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useGlobal('user');

  const logout = () => {
    setUser({ name: '', type: 'INVESTOR' });
    authService.unauthenticated();
    history.push('/');
  };

  return (
    <div className={classes.mobileNavbar}>
      <div className={classes.leftArea}></div>
      <div className={classes.centerArea}>
        <div className={classes.firstLine}>
          <div className={classes.menuArea}>
            {user.type === 'REFFERER' || history.location.pathname === '/indicador' ? null : (
              <IconButton
                fontSize='large'
                className={classes.iconButton}
                onClick={() => eventService.emitEvent(EventTypes.OPEN_OPTIONS_MENU)}
              >
                <MenuIcon fontSize='large' />
              </IconButton>
            )}
          </div>
          <div className={classes.logoArea}>
            <a href='https://ameaconstrutora.com.br/' style={{ outline: 0 }}>
              <img src={logo} alt='Logo' className={classes.mobileLogo}></img>
            </a>
          </div>
          <div className={classes.userArea}>
            <IconButton color={'secondary'} size={'small'} onClick={(event) => setAnchorEl(event.currentTarget)}>
              <ProfileIcon fontSize={'large'} />
            </IconButton>
            <IconButton className={classes.iconButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
              <ExpandMoreIcon />
            </IconButton>
            <NavbarMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} onLogout={logout} />
          </div>
        </div>
        {user.type === 'INVESTOR/REFERRER' ? (
          <div className={classes.secondLine}>
            <div className={classes.userTypeArea}>
              <NavbarSelect user={user} history={history} />
            </div>
          </div>
        ) : null}
      </div>
      <div className={classes.rightArea}></div>
    </div>
  );
};

export default withRouter(MobileNavbar);
