import React from 'react';
import { makeStyles, Typography, Paper } from '@material-ui/core';
import CountUp from 'react-countup';
import InvestmentSimulator from '../Investment-simulator/InvestmentSimulator';
import Chart from '../Charts';
import ProgressLevelIndicators from '../Construction-progress/ProgressLevelIndicators';

const useStyles = makeStyles((theme) => ({
  mobileReferrer: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  indicationsValues: {
    flex: 0.1,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(1),
    display: 'flex',
  },
  indicationValueArea: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  indicationValueTitle: {
    fontFamily: 'Montserrat',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  investmentSimulatorArea: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: 0.1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },

  investmentSimulator: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  chartArea: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    flex: 1,
  },
  indicationChartPaper: {
    padding: theme.spacing(1),
  },
  levelsArea: {
    flex: 0.1,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  progressIndicator: {
    padding: theme.spacing(1),
  },
}));
const MobileReferrer = ({
  totalIndications,
  totalCommissions,
  onSimulate,
  onCloseInvestmentSimulator,
  levels,
  currentLevel,
  totalIndicationsChart,
}) => {
  const classes = useStyles();

  const IndicationInfo = ({ title, value, style }) => (
    <div className={classes.indicationValueArea} style={{ ...style }}>
      <Typography className={classes.indicationValueTitle} variant='body2'>
        {title}
      </Typography>
      <Typography className={classes.indicationValue} variant='body2'>
        <CountUp
          start={0}
          end={value}
          duration={3}
          separator={'.'}
          prefix={'R$'}
          style={{
            fontFamily: 'Montserrat',
            fontWeight: 'bold',
            color: '#52575D',
            fontSize: 20,
          }}
        ></CountUp>
      </Typography>
    </div>
  );

  return (
    <div className={classes.mobileReferrer}>
      <div className={classes.indicationsValues}>
        <IndicationInfo
          title='Total investido através das suas indicações'
          value={totalIndications}
          style={{ flex: 1, borderRight: '1px solid #ECECEC' }}
        />
        <IndicationInfo
          title='Comissão por indicação'
          value={totalCommissions}
          style={{
            flex: 0.8,
            paddingLeft: 15,
          }}
        />
      </div>
      <div className={classes.investmentSimulatorArea}>
        <div className={classes.investmentSimulator}>
          <InvestmentSimulator onSimulate={onSimulate} onCancel={onCloseInvestmentSimulator} />
        </div>
      </div>
      <div className={classes.chartArea}>
        <Paper className={classes.indicationChartPaper} elevation={5}>
          <Chart
            chartTitle={'Indicações e Acumulado'}
            data={totalIndicationsChart}
            chartType='bar'
            yMoney
            chartColors={['#5693F4', '#FFB300', '#0FD901', '#ff0000', '#c3cde4']}
            width={350}
            height={400}
            yMin={0}
          />
        </Paper>
      </div>
      <div className={classes.levelsArea}>
        {levels && levels?.length ? (
          <ProgressLevelIndicators
            currentLevel={currentLevel}
            levels={levels}
            totalIndications={totalIndications}
            classes={classes}
          />
        ) : null}
      </div>
    </div>
  );
};
export default MobileReferrer;
